import { Edit, X } from 'feather-icons-react';
import { CSSProperties, useEffect, useState } from 'react';
import { formatDateFromString, formatNumber } from 'utils';

import { ReactComponent as Speedometer } from 'assets/images/icons/speedometer.svg';

import { EditWidgetsModal } from 'components/WidgetControl/EditWidgetsModal';
import {
  Card,
  IconButton,
  InfoTooltip,
  Modal,
  Popover,
  ProgressLine,
  Skeleton,
} from 'components/common';
import { METRIC_COLORS, MetricKeys } from 'components/constants';

import { FREQUENCY_METRIC, REACH_METRIC } from 'modules/performance/constants';
import { WidgetOption } from 'modules/performance/performanceApiTypes';
import { usePerformance } from 'modules/performance/usePerformance';

const MAX_WIDGET_LENGTH = 6;

interface WidgetControlProps {
  selectedMetrics: string[];
  onChange: (metrics: string[]) => void;
}

export const WidgetControl = ({ selectedMetrics, onChange }: WidgetControlProps) => {
  const [metrics, setMetrics] = useState<string[]>([]);
  const [selectedCards, setSelectedCards] = useState<string[]>(selectedMetrics);

  const [hovered, setHovered] = useState<{ [key: string]: boolean }>({});
  const [isOpenWidgetsModal, setIsOpenWidgetsModal] = useState(false);
  const {
    getWidgets,
    displayWidgets,
    isWidgetsLoading,
    selectedView,
    budget,
    setCustomView,
    setViewId,
    isCumulative,
  } = usePerformance();

  useEffect(() => {
    selectedView && !displayWidgets && getWidgets();
  }, [selectedView, getWidgets, displayWidgets]);

  useEffect(() => {
    if (displayWidgets) {
      const selectedDefault = displayWidgets
        .filter((widget) => widget.selected)
        .map((widget) => widget.key);

      setMetrics(displayWidgets.map((widget) => widget.key));
      setSelectedCards(selectedDefault);
      onChange(selectedDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayWidgets, setSelectedCards]);

  const handleRemoveCard = (cardKey: string) => {
    const selectedMetrics = metrics.filter((id) => id !== cardKey);

    setMetrics(selectedMetrics);
    setCustomView(selectedMetrics);
    setViewId('0');
  };

  const isDisabled = (cardKey: string) => {
    return (cardKey === FREQUENCY_METRIC || cardKey === REACH_METRIC) && isCumulative;
  };

  const toggleSelectCard = (cardKey: string) => {
    if (isDisabled(cardKey)) return;

    setSelectedCards((prevSelectedCards) => {
      const updatedSelectedCards = prevSelectedCards.includes(cardKey)
        ? prevSelectedCards.filter((id) => id !== cardKey)
        : [...prevSelectedCards, cardKey];

      onChange(updatedSelectedCards);

      return updatedSelectedCards;
    });
  };

  const isSelected = (cardKey: string) => selectedCards.includes(cardKey);

  const getPopoverContent = (option: WidgetOption) => {
    if (option.hasPopover && budget) {
      return (
        <Card title="Budget and pacing">
          <div className="flex-col justify-start items-start gap-6 inline-flex">
            <div className="justify-start items-start gap-10 inline-flex">
              <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                <div className="text-base-xs font-medium leading-none">Start date</div>
                <div className="text-base-sm font-normal leading-tight">
                  {formatDateFromString(budget?.startDate)}
                </div>
              </div>
              <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                <div className="text-base-xs font-medium leading-none">End date</div>
                <div className="text-base-sm font-normal leading-tight">
                  {formatDateFromString(budget?.endDate)}
                </div>
              </div>
            </div>
            <div className="self-stretch h-[66px] flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch h-[52px] flex-col justify-start items-start gap-1.5 flex">
                <div className="text-base-xs font-medium leading-none">Total Spend</div>
                <div className="text-heading-2xl font-bold leading-[30px]">
                  ${formatNumber(budget?.spend || 0)} / ${formatNumber(budget?.budget || 0)}
                </div>
              </div>
              <ProgressLine percent={budget?.pacing || 0} showInfo={false} className="w-[272px]" />
            </div>
            <div className="justify-start items-center gap-1.5 inline-flex">
              <div className="font-medium leading-none">
                <span className="text-base-xs">Pacing: </span>
                <span
                  className={`text-base-xs ${
                    budget.pacing! >= 0 ? 'text-system-success-500' : 'text-system-danger-500'
                  }`}
                >
                  {budget?.pacing}%
                </span>
              </div>
              <InfoTooltip
                title={
                  <div className="text-base-sm font-normal leading-tight">
                    This value represents how much has been spent versus the projected spend up
                    until today.
                  </div>
                }
              />
            </div>
          </div>
        </Card>
      );
    }
  };

  const handleEditAction = () => {
    setIsOpenWidgetsModal(true);
  };

  const getPreviousPeriodTitle = (card: WidgetOption) => {
    switch (card.timeSliceOption) {
      case 'CUSTOM':
        return 'custom previous period';
      case 'LAST_7_DAYS':
        return 'previous 7 days';
      case 'LAST_30_DAYS':
        return 'previous 30 days';
      case 'LAST_90_DAYS':
        return 'previous 90 days';
      case 'LAST_365_DAYS':
        return 'previous 365 days';
      case 'LAST_MONTH':
        return 'previous month';
      case 'LAST_QUARTER':
        return 'previous quarter';
      case 'LAST_WEEK':
        return 'previous week';
      case 'LAST_YEAR':
        return 'previous year';
      case 'MONTH_TO_DATE':
        return 'same period last month';
      case 'TODAY':
        return 'yesterday';
      case 'WEEK_TO_DATE':
        return 'same period last week';
      case 'YEAR_TO_DATE':
        return 'same period last year';
      case 'YESTERDAY':
        return 'two days ago';
      default:
        return card.timeSlice;
    }
  };

  const handleMouseEnter = (cardKey: string) => {
    setHovered((prevHovered) => ({ ...prevHovered, [cardKey]: true }));
  };

  const handleMouseLeave = (cardKey: string) => {
    setHovered((prevHovered) => ({ ...prevHovered, [cardKey]: false }));
  };

  return isWidgetsLoading ? (
    <div className="widget-container flex w-full">
      {Array.from({ length: MAX_WIDGET_LENGTH }).map((_, index) => (
        <Skeleton key={index} className="flex-1 h-[140px]" />
      ))}
    </div>
  ) : (
    displayWidgets && (
      <div className="widget-container flex w-full">
        {displayWidgets.slice(0, MAX_WIDGET_LENGTH).map((card, index) => {
          const cardContent = (
            <div
              key={index}
              className="card-item flex-1 h-[140px]"
              onMouseEnter={() => handleMouseEnter(card.key)}
              onMouseLeave={() => handleMouseLeave(card.key)}
            >
              <Card
                className={`${isSelected(card.key) && !isDisabled(card.key) ? 'selected' : null} w-full h-full`}
                onClick={() => toggleSelectCard(card.key)}
                style={
                  { '--ctv-widget-color': METRIC_COLORS[card.key as MetricKeys] } as CSSProperties
                }
              >
                {hovered[card.key] && (
                  <IconButton
                    className="absolute top-2 right-2 "
                    variant={'secondary'}
                    size="sm"
                    Icon={X}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveCard(card.key);
                    }}
                  />
                )}
                <div className="h-5 justify-start items-center gap-2 inline-flex mb-auto">
                  <div className="text-base-sm font-medium leading-tight">{card.displayName}</div>
                  {card.hasPopover && (
                    <div className="flex px-2 py-1 items-center rounded-full bg-primary-gray-50 text-primary-electric-500 border border-primary-gray-100 gap-1">
                      <Speedometer />
                      <div className="text-base-sm font-medium leading-tight">
                        {budget?.spendToBudget ? budget?.spendToBudget : 0}%
                      </div>
                    </div>
                  )}
                </div>
                <div className="text-heading-3xl font-bold leading-9">
                  {card.unit === '%' ? '' : card.unit}
                  {formatNumber(card.current!)}
                  {card.unit === '%' ? '%' : ''}
                </div>
                <div
                  className={`text-base-xs font-medium leading-none ${
                    card.percent! >= 0 ? 'text-system-success-500' : 'text-system-danger-500'
                  }`}
                >
                  {card.percent}
                  {card.timeSlice ? '% vs. ' + getPreviousPeriodTitle(card) : ''}
                </div>
              </Card>
            </div>
          );

          return card.hasPopover ? (
            <Popover
              key={index}
              placement="bottomLeft"
              trigger="hover"
              content={getPopoverContent(card)}
            >
              {cardContent}
            </Popover>
          ) : (
            cardContent
          );
        })}
        <div
          className="action-container h-[140px] w-16 p-3 justify-center items-center inline-flex
              rounded-none rounded-tr-lg rounded-br-none shadow-none border-l-0 border-r-0 border-t-transparent"
        >
          <IconButton size="md" variant="secondary" Icon={Edit} onClick={handleEditAction} />
        </div>
        <Modal
          open={isOpenWidgetsModal}
          closable={false}
          footer={null}
          centered
          onCancel={() => setIsOpenWidgetsModal(false)}
        >
          <EditWidgetsModal onClose={() => setIsOpenWidgetsModal(false)} />
        </Modal>
      </div>
    )
  );
};
