import { Flex } from 'antd';
import { ReactElement, useState } from 'react';

import { ReactComponent as IconCryptocurrency } from 'assets/images/icons/cryptocurrency.svg';
import { ReactComponent as IconLayout } from 'assets/images/icons/layout-alt-tab.svg';
import { ReactComponent as IconRoute } from 'assets/images/icons/route.svg';

import { FilterDimensionGrid } from 'components/FilterDimensionGrid';
import { HaloEffect } from 'components/HaloEffect';
import { HaloEffectFilter } from 'components/HaloEffect/HaloEffectFilter';
import { Incrementality } from 'components/Incrementality';
import { IncrementalityFilter } from 'components/Incrementality/IncrementalityFilter';
import { EditColumnsModal } from 'components/PerformanceTable/EditColumnsModal';
import { Button, Card, Modal, Spin, Tabs } from 'components/common';

import { DimensionFilter, TabKeys } from 'modules/performance/performanceApiTypes';
import { usePerformance } from 'modules/performance/usePerformance';

import { AdvancedAnalyticsTable } from './AdvancedAnalyticsTable';

const createTab = (icon: ReactElement, label: string) => (
  <div className="flex gap-1">
    {icon}
    {label}
  </div>
);

const noDataTemplate = (
  <div className="h-[188px] w-full py-16 justify-center items-center inline-flex">
    <div className="w-[340px] text-center text-primary-gray-300 text-heading-2xl font-semibold leading-[30px]">
      Looks like there’s no data. Please try again later.
    </div>
  </div>
);

const singleCampaignTemplate = (pageLabel: string, callback: () => void) => (
  <div className="w-full min-h-128 py-16 flex flex-col items-center gap-6">
    <div className="w-96 text-primary-gray-300 text-heading-2xl font-semibold leading-[30px] tracking-tighter">
      You must filter a single campaign in order to view {pageLabel}.
    </div>
    <Button size="md" className="pl-6 " onClick={callback}>
      Choose new campaign
    </Button>
  </div>
);

const hasSingleElementInCampaign = (campaignFilter: DimensionFilter) => {
  const { include } = campaignFilter;
  const includeLength = include ? include.length : 0;

  return includeLength === 1;
};

const tabElements = [
  {
    key: TabKeys.REAL_IMPACT.value,
    label: TabKeys.REAL_IMPACT.label,
    icon: <IconLayout />,
  },
  { key: TabKeys.INCREMENTALITY.value, label: TabKeys.INCREMENTALITY.label, icon: <IconRoute /> },
  {
    key: TabKeys.RIPPLE_EFFECT.value,
    label: TabKeys.RIPPLE_EFFECT.label,
    icon: <IconCryptocurrency />,
  },
];

export const PerformanceContent = ({
  selectedDimensions,
  onChangeDimension,
  openGlobalFilter,
}: {
  selectedDimensions: [string | null, string | null];
  onChangeDimension: (dimensions: [string | null, string | null]) => void;
  openGlobalFilter: () => void;
}) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const {
    globalFilterDimensions,
    incrementalities,
    halo,
    pickIncrementalityFilter,
    incrementalityFilters,
    pickActiveTab,
    activeTab,
    haloFilter,
    pickHaloFilter,
    isIncrementalityLoading,
    isHaloLoading,
  } = usePerformance();

  const onTabChange = (key: string) => {
    pickActiveTab(key);
  };

  const handleEditColumnsAction = () => {
    setIsOpenEditModal(true);
  };

  const checkSelectedDimension = () => {
    const allowedDimension = 'CAMPAIGN';
    const foundCampaigns = globalFilterDimensions?.filter(
      ({ dimension }) => dimension === allowedDimension,
    );

    return (
      foundCampaigns && foundCampaigns.length === 1 && hasSingleElementInCampaign(foundCampaigns[0])
    );
  };

  const getTabContent = (key: string) => {
    switch (key) {
      case TabKeys.REAL_IMPACT.value:
        return (
          <div>
            <div className="h-[52px] px-6 pt-3 flex justify-end items-center gap-6">
              <FilterDimensionGrid
                selectedDimensions={selectedDimensions}
                onChangeDimension={onChangeDimension}
              />
              <Button size="md" onClick={handleEditColumnsAction}>
                Edit columns
              </Button>
              <Modal
                open={isOpenEditModal}
                closable={false}
                footer={null}
                centered
                onCancel={() => setIsOpenEditModal(false)}
              >
                <EditColumnsModal onClose={() => setIsOpenEditModal(false)} />
              </Modal>
            </div>
            <AdvancedAnalyticsTable />
          </div>
        );
      case TabKeys.INCREMENTALITY.value:
        return (
          <Spin spinning={isIncrementalityLoading}>
            {checkSelectedDimension() ? (
              incrementalities && incrementalities.length > 0 ? (
                <Incrementality openGlobalFilter={openGlobalFilter} />
              ) : (
                <>
                  <Flex gap="small" className="justify-end pr-3 pt-3">
                    <IncrementalityFilter
                      onChange={pickIncrementalityFilter}
                      incrementalityFilter={incrementalityFilters}
                    />
                    <Button size="md" className="ml-6 " onClick={openGlobalFilter}>
                      Choose new campaign
                    </Button>
                  </Flex>
                  {noDataTemplate}
                </>
              )
            ) : (
              singleCampaignTemplate('Incrementality', openGlobalFilter)
            )}
          </Spin>
        );
      case TabKeys.RIPPLE_EFFECT.value:
        return (
          <Spin spinning={isHaloLoading}>
            {checkSelectedDimension() ? (
              halo && halo.haloByChannels.length > 0 ? (
                <HaloEffect openGlobalFilter={openGlobalFilter} />
              ) : (
                <>
                  <Flex gap="small" className="justify-end pr-3 pt-3">
                    <HaloEffectFilter onChange={pickHaloFilter} haloFilter={haloFilter} />
                    <Button size="md" className="ml-6 " onClick={openGlobalFilter}>
                      Choose new campaign
                    </Button>
                  </Flex>
                  {noDataTemplate}
                </>
              )
            ) : (
              singleCampaignTemplate('Ripple Effect', openGlobalFilter)
            )}
          </Spin>
        );
    }
  };

  const tabsConfig = tabElements.map((tab) => ({
    key: tab.key.toString(),
    label: createTab(tab.icon, tab.label),
    children: getTabContent(tab.key.toString()),
  }));

  return (
    <Card className="ctv-performance-table shadow-none border border-primary-gray-100">
      <Tabs items={tabsConfig} activeKey={activeTab} onChange={onTabChange} />
    </Card>
  );
};
