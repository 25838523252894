import { PerformanceSidebar } from 'components/PerformanceSidebar/PerformanceSidebar';
import { InfoTooltip } from 'components/common';

import { usePerformance } from 'modules/performance/usePerformance';

export const HaloEffectSidebar = ({
  onCollapse,
}: {
  onCollapse?: (collapsed: boolean) => void;
}) => {
  const { halo } = usePerformance();

  const getPercentage = (fraction?: number, total?: number) =>
    (fraction || fraction === 0) && total ? `${((fraction / total) * 100).toFixed(0)}%` : '';

  const percentage = getPercentage(halo?.totalExposedConversions, halo?.totalControlConversions);

  return (
    <PerformanceSidebar onCollapse={onCollapse}>
      <div className="self-stretch grow shrink basis-0 px-4 pb-4 flex-col justify-between items-start flex">
        <div className="self-stretch  flex-col justify-start items-start gap-6 flex">
          <div className="self-stretch  flex-col justify-start items-start gap-1 flex">
            <div className="text-heading-5xl text-primary-electric-500 font-bold leading-[52px]">
              {halo?.totalExposedConversions.toLocaleString()}
            </div>
            <div className="self-stretch text-base-lg text-primary-gray-500 font-medium leading-normal">
              Conversions that were exposed to COOL Media Advertising
            </div>
          </div>
          <div className="self-stretch h-px bg-primary-gray-100" />

          <div className="self-stretch  flex-col justify-start items-start gap-1 flex">
            <div className="text-heading-5xl text-secondary-black font-bold leading-[52px]">
              {halo?.totalControlConversions.toLocaleString()}
            </div>
            <div className="self-stretch text-base-lg text-primary-gray-500 font-medium leading-normal">
              Conversions that were not exposed to COOL Media Advertising
            </div>
          </div>

          {percentage ? (
            <>
              <div className="self-stretch h-px bg-primary-gray-100" />
              <div className="self-stretch  flex-col justify-start items-start gap-1 flex">
                <div className="text-heading-5xl text-secondary-black font-bold leading-[52px]">
                  {percentage}
                </div>
                <div className="self-stretch text-base-lg text-primary-gray-500 font-medium leading-normal">
                  Of the total conversions on site were influenced by COOL Media Advertising
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="text-primary-gray-500 mt-5 justify-start items-center gap-1.5 inline-flex">
          <InfoTooltip
            title={
              <div className="text-base-sm font-normal leading-tight">
                Ripple Effect utilizes UTCs and cross examining conversions that had exposure to
                your campaigns with those that didn’t in order to measure the effectiveness.
              </div>
            }
          />
          <div className="text-base-sm font-medium leading-tight">How Ripple Effect works</div>
        </div>
      </div>
    </PerformanceSidebar>
  );
};
