/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridSortModel } from '@mui/x-data-grid';
import { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { DateRangeValue } from 'components/common/dateRangePicker';

export const deepCopy = (data: unknown) => JSON.parse(JSON.stringify(data));

export const arrayToMap = <T, K extends keyof T>(list: T[], key: K) => {
  return list.reduce(
    (acc, item) => {
      acc[String(item[key])] = item;

      return acc;
    },
    {} as Record<string, T>,
  );
};

export const copyToClipboard = (data: string) => {
  if (data.length > 0) {
    navigator.clipboard.writeText(data);
  }
};

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;

  return String(error);
};

export const capitalize = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};

export const formatNumber = (num: number | null | undefined, fraction = 2): string => {
  if (num === 0 || num === null || num === undefined) {
    return '0';
  }

  if (num >= 1000000) {
    return `${(Math.round(num / 10000) / 100).toFixed(fraction)}m`;
  }

  if (num >= 1000) {
    return `${(Math.round(num / 10) / 100).toFixed(fraction)}k`;
  }

  if (num < 1000) {
    if (num % 1 === 0) {
      return num.toString();
    } else {
      return parseFloat(num.toFixed(fraction)).toString();
    }
  }

  return num.toString();
};

export const formatNumberToLocale = (
  num: number,
  options?: Intl.NumberFormatOptions & {
    locales?: Intl.LocalesArgument;
    prefix?: string;
    suffix?: string;
  },
): string => {
  const defaultOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
  };
  const { locales = 'en-US', prefix = '', suffix = '', ...rest } = options || {};
  const numberFormatOptions = { ...defaultOptions, ...rest };

  if (numberFormatOptions.style === 'currency' && !numberFormatOptions.currency) {
    numberFormatOptions.currency = 'USD';
  }

  return `${prefix}${num.toLocaleString(locales, numberFormatOptions)}${suffix}`;
};

export function arraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

export const formatDate = (date: Dayjs | null | undefined): string => {
  if (date === null || date === undefined) return '';

  return date?.format('YYYY-MM-DD HH:mm:ss.SSS');
};

export const formatDateFromString = (dateString: string | null | undefined): string => {
  if (!dateString) return 'N/A';

  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString().slice(-2);

  return `${day}/${month}/${year}`;
};

export const findKeyByValue = (obj: any, searchValue: string): string => {
  for (const key in obj) {
    if (obj[key] === searchValue) {
      return key;
    }
  }

  return '';
};

type OmitProps<T, K extends keyof T> = Omit<T, K>;

export const omitProps = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keys: K[],
): OmitProps<T, K> => {
  const { [keys[0]]: _, ...rest } = obj;

  return keys.length > 1 ? omitProps(rest as T, keys.slice(1) as K[]) : rest;
};

export const formatSortingParams = (sortModel: GridSortModel) =>
  sortModel.length > 0
    ? { sortBy: sortModel[0].field, direction: sortModel[0].sort?.toUpperCase() }
    : {};

const isWithinSamePeriod = (
  range: DateRangeValue | undefined,
  unit: 'week' | 'month' | 'year',
): boolean => {
  if (!range || !range[0] || !range[1]) return false;

  const startDate = range[0]!;
  const endDate = range[1]!;

  return endDate.isSame(startDate, unit);
};

export const getDifferenceInDays = (range: DateRangeValue | undefined): number => {
  if (!range || !range[0] || !range[1]) return 0;

  const startDate = range[0]!;
  const endDate = range[1]!;

  return endDate.diff(startDate, 'days');
};

export const isWithinSameWeek = (range: DateRangeValue | undefined): boolean =>
  isWithinSamePeriod(range, 'week');

export const isWithinSameMonth = (range: DateRangeValue | undefined): boolean =>
  isWithinSamePeriod(range, 'month');

export const isWithinSameYear = (range: DateRangeValue | undefined): boolean =>
  isWithinSamePeriod(range, 'year');

export const expandConstants = <T>(obj: { [key: string]: T }) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, { value: key, label: value }]),
  );
